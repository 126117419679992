import { VStack, Text, Box } from '@chakra-ui/react'
import React from 'react'

const EventBdubs: React.FC = () => (
  <VStack>
    <Text>SAVE THE DATE!!</Text>
    <Text>Thursday 6/13/24 (all day)</Text>
    <Text>
      Independence Bdubs &{' '}
      <Box as='span' fontFamily='Cherry Swash' color='blue.500'>
        Shine Like Lauren, Inc.
      </Box>{' '}
      are partnering to fundraise! 10% of all sales that day will go back to our
      foundation! Keep Lauren Collins light shining by helping to fund
      scholarships for local high school students!
    </Text>
  </VStack>
)

export default EventBdubs
