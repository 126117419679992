import React, { ReactNode } from 'react'
import { Heading, Box } from '@chakra-ui/react'

interface LayoutProps {
  page_title?: string
  children: ReactNode
  width?: string
}

const Layout: React.FC<LayoutProps> = ({
  page_title,
  children,
  width,
}): React.ReactElement => {
  const max_width = width || '800px'

  return (
    <>
      <Box mx='auto' maxWidth={max_width} px='4'>
        {page_title && (
          <Heading
            as='h3'
            mt='4'
            ml={{ base: '4', sm: '0' }}
            mb='4'
            fontFamily='Cherry Swash'
            color='blue.500'>
            {page_title}
          </Heading>
        )}
        {children}
      </Box>
    </>
  )
}

export default Layout
