import { VStack, Text, Box } from '@chakra-ui/react'
import React from 'react'

const EventSkyline: React.FC = () => (
  <VStack>
    <Text width='100%'>
      Skyline Chili Crescent Springs & Ft. Wright will donate 15% of their sales
      on 7/16/2024 (open to close) to{' '}
      <Box as='span' fontFamily='Cherry Swash' color='blue.500'>
        Shine Like Lauren, Inc.
      </Box>{' '}
      to help fund scholarships for local high school students.
    </Text>
    <Text width='100%'>
      Please join us on this day as this will mark a year since Lauren's
      accident.
    </Text>
    <Text width='100%'>
      The NKY community has been so supportive! We hope seeing friendly faces on
      this day will bring some light for our families. Keep Lauren Collin's
      light shining!
    </Text>
  </VStack>
)

export default EventSkyline
