import { ReactNode, useState } from 'react'
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  useDisclosure,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Collapse,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { NavLink as ReactRouterNavLink, useLocation } from 'react-router-dom'
import { useColorModeValue } from '@chakra-ui/react'

const Links = [
  { label: 'Home', to: '/' },
  { label: 'Donate', to: '/donate' },
  {
    label: 'Blue & White Night Event Auction',
    to: 'https://givebutter.com/c/SLLBWN/auction',
    external: true,
  },
  { label: 'Events', to: '/events' },
  { label: 'Scholarship Recipients', to: '/recipients' },
  { label: 'Sponsors', to: '/sponsors' },
]

const Galleries = [
  { label: 'Lauren Gallery', to: '/gallery' },
  { label: 'Event Gallery', to: '/event-gallery' },
]

const NavLink = ({
  children,
  to,
  onClose,
}: {
  children: ReactNode
  to: string
  onClose: () => void
}) => {
  const location = useLocation()
  const isActive = location.pathname === to
  const linkColor = isActive ? 'gray.700' : 'gray.500'

  return (
    <Link
      as={ReactRouterNavLink}
      to={to}
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: 'gray.200',
      }}
      color={linkColor}
      onClick={onClose}>
      {children}
    </Link>
  )
}

export default function Nav() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [showGalleries, setShowGalleries] = useState(false)
  const location = useLocation()
  const bgColor = useColorModeValue('gray.100', 'gray.900')

  // Determine if one of the gallery pages is active
  const isGalleryActive = Galleries.some(({ to }) => location.pathname === to)
  const galleryLinkColor = isGalleryActive ? 'gray.700' : 'gray.500'

  const toggleGalleries = () => setShowGalleries(!showGalleries)

  return (
    <>
      <Box bg={bgColor} px={4}>
        <Flex
          h={16}
          alignItems={'center'}
          justifyContent={'space-between'}
          w='100%'>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <Flex alignItems={'center'} w='100%'>
            <Avatar size={'sm'} src={'favicon.png'} />
            <Box display={{ base: 'none', md: 'flex' }}>
              <HStack spacing={4} alignItems={'center'}>
                {Links.map(({ label, to }) => (
                  <NavLink key={to} to={to} onClose={() => {}}>
                    {label}
                  </NavLink>
                ))}
                <Menu>
                  <MenuButton
                    as={Link}
                    px={2}
                    py={1}
                    rounded={'md'}
                    _hover={{ textDecoration: 'none', bg: 'gray.200' }}
                    color={galleryLinkColor} // Apply the active/inactive color
                  >
                    <Flex alignItems='center'>
                      Photo Galleries
                      <ChevronDownIcon ml={2} />
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    {Galleries.map(({ label, to }) => (
                      <MenuItem
                        as={ReactRouterNavLink}
                        key={to}
                        to={to}
                        onClick={onClose}>
                        {label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
                <NavLink key='/about' to='/about' onClose={() => {}}>
                  About Us
                </NavLink>
              </HStack>
            </Box>
          </Flex>
        </Flex>
      </Box>
      {isOpen && (
        <Box bg={bgColor} p={4} w='100%'>
          <VStack spacing={4} alignItems={'flex-start'}>
            {Links.map(({ label, to }) => (
              <NavLink key={to} to={to} onClose={onClose}>
                {label}
              </NavLink>
            ))}
            <Text
              cursor='pointer'
              onClick={toggleGalleries}
              px={2}
              py={1}
              rounded={'md'}
              _hover={{ textDecoration: 'none', bg: 'gray.200' }}
              color={galleryLinkColor} // Apply the active/inactive color
            >
              <Flex alignItems='center'>
                Photo Galleries
                <ChevronDownIcon ml={2} />
              </Flex>
            </Text>
            <Collapse in={showGalleries} animateOpacity>
              <VStack pl={4} alignItems='flex-start'>
                {Galleries.map(({ label, to }) => (
                  <NavLink key={to} to={to} onClose={onClose}>
                    {label}
                  </NavLink>
                ))}
              </VStack>
            </Collapse>
            <NavLink key='/about' to='/about' onClose={onClose}>
              About Us
            </NavLink>
          </VStack>
        </Box>
      )}
    </>
  )
}
