import { extendTheme } from '@chakra-ui/react'

import { defineStyle } from '@chakra-ui/react'

const HeadingTheme = defineStyle({
  color: 'blue.500',
  fontFamily: 'mono',
  fontWeight: 'semibold',
  _dark: {
    color: 'blue.300',
  },
})

const Theme = extendTheme({
  components: { Heading: HeadingTheme },
})

export default Theme
