import React, { useState, useEffect } from 'react'
import { Box, Heading, Text, Stack, Image, Skeleton } from '@chakra-ui/react'
import recipientsData from '../data/recipients.json'
import Layout from '../components/Layout'

interface Recipient {
  id: number
  name: string
  photo: string
  bio: string
}

const Recipients: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [recipientsByYear, setRecipientsByYear] = useState<
    Record<string, Recipient[]>
  >({})

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 500)

    setRecipientsByYear(recipientsData)

    return () => clearTimeout(timer)
  }, [])

  return (
    <Layout page_title='Scholarship Recipients'>
      {loading ? (
        <Stack spacing={8}>
          {[1, 2, 3].map(index => (
            <Skeleton key={index} height='200px' />
          ))}
        </Stack>
      ) : (
        <>
          {Object.entries(recipientsByYear).map(([year, recipients]) => (
            <Box key={year} mt={8}>
              <Heading
                as='h3'
                fontSize='2xl'
                mb={4}
                fontFamily='Cherry Swash'
                color='blue.500'>
                {year}
              </Heading>
              {recipients.map(recipient => (
                <Box
                  key={recipient.id}
                  p={4}
                  borderWidth='1px'
                  borderRadius='lg'
                  overflow='hidden'
                  mb={4}
                  display='flex'
                  flexDirection={{ base: 'column', md: 'row' }}
                  alignItems='center'>
                  <Image
                    src={recipient.photo}
                    alt={recipient.name}
                    objectFit='cover'
                    borderRadius='full'
                    boxSize={{ base: '100px', md: '200px' }}
                    mr={{ base: 0, md: 4 }}
                    mb={{ base: 4, md: 0 }}
                  />
                  <Stack spacing={2}>
                    <Heading
                      size='md'
                      fontFamily='Cherry Swash'
                      color='blue.500'>
                      {recipient.name}
                    </Heading>
                    <Text>{recipient.bio}</Text>
                  </Stack>
                </Box>
              ))}
            </Box>
          ))}
        </>
      )}
    </Layout>
  )
}

export default Recipients
