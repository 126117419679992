import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  VStack,
  Text,
  UnorderedList,
  ListItem,
  Divider,
  Link,
} from '@chakra-ui/react'
import React from 'react'

const EventBlueAndWhite2025: React.FC = () => (
  <Box p={4}>
    <Heading
      as='h2'
      size='lg'
      fontFamily='Cherry Swash'
      mb={4}
      color='blue.600'>
      Event Details
    </Heading>
    <VStack align='start' spacing={4} mb={8}>
      <Text>
        We will be back for the second annual fundraiser and we can't wait! On
        sale date and ticket prices forthcoming. Come dressed to impress in blue
        and white!!
      </Text>
      {/* <Text>
        5:00pm starts the VIP Cocktail hour (open bar & appetizers) is included
        for some sponsorship levels, and there will be a limited number of
        additional tickets for purchase in advance. General Event will be
        6pm-10pm and those tickets will include dinner, 3 drink tickets, live
        music, dancing, Q102 emcee Mollie Watson, Auction/Raffle! General event
        tickets will be limited to approx 180 tickets. Start getting your groups
        of 10 together and secure your table, we anticipate a sold out event!
        Tables will be sold before individual tickets are released.
      </Text> */}
      <Text>
        We continue to welcome corporate and individual sponsors for the event
        as well as items for raffle and silent auction.
      </Text>
      <Text>
        All proceeds will go toward funding scholarships for local high school
        students in memory of our daughter Lauren Collins. Help us celebrate and
        remember 💙. * We are a registered tax exempt, 501c3 nonprofit charity*
      </Text>
    </VStack>
    {/* <Divider orientation='horizontal' />
    <Heading
      as='h2'
      size='lg'
      fontFamily='Cherry Swash'
      mb={4}
      color='blue.600'>
      Event Auction
    </Heading>
    <VStack align='start' spacing={4} mb={8}>
      <Text>
        Don't Miss Out on Our Blue and White Night Auction! From exclusive items
        to unique experiences, our auction has something for everyone. Your
        participation will directly benefit Shine Like Lauren, Inc, helping us
        continue our mission of making a positive impact in our community. Visit
        our{' '}
        <Link
          isExternal
          color='blue.500'
          href='https://givebutter.com/c/SLLBWN/auction'>
          online auction
          <ExternalLinkIcon mx='1px' />
        </Link>{' '}
        now and place your bids. Every contribution counts and brings us one
        step closer to our goals. Thank you for your support and generosity.
      </Text>
    </VStack> */}
    {/* <Heading
      as='h2'
      size='lg'
      fontFamily='Cherry Swash'
      mb={4}
      color='blue.600'>
      Event Tickets
    </Heading>
    <Box
      as='div'
      dangerouslySetInnerHTML={{
        __html: '<givebutter-widget id="p7AOZL"></givebutter-widget>',
      }}></Box>
    <Divider orientation='horizontal' /> */}
    <Heading
      as='h2'
      size='lg'
      fontFamily='Cherry Swash'
      mb={4}
      color='blue.600'>
      Event Sponsorship Levels
    </Heading>
    <Stack spacing={4}>
      <Card
        border='1px'
        borderColor='gray.200'
        borderRadius='md'
        boxShadow='sm'>
        <CardHeader>
          <Heading size='md' fontFamily='Cherry Swash' color='blue.500'>
            Thoroughbred Sponsor - $500 +
          </Heading>
        </CardHeader>
        <CardBody>
          <UnorderedList>
            <ListItem>Company Name/Logo on website/social media</ListItem>
            <ListItem>Logo Printed in Event Pamphlet</ListItem>
          </UnorderedList>
        </CardBody>
      </Card>
      <Card
        border='1px'
        borderColor='gray.200'
        borderRadius='md'
        boxShadow='sm'>
        <CardHeader>
          <Heading size='md' fontFamily='Cherry Swash' color='blue.500'>
            Pioneer Sponsor - $1,000 +
          </Heading>
        </CardHeader>
        <CardBody>
          <UnorderedList>
            <ListItem>Company Name/Logo on website/social media</ListItem>
            <ListItem>Logo Printed in Event Pamphlet</ListItem>
            <ListItem>Small Company Logo on Event Banner/slideshow</ListItem>
            <ListItem>2 Tickets to VIP Cocktail Hour prior to event</ListItem>
          </UnorderedList>
        </CardBody>
      </Card>
      <Card
        border='1px'
        borderColor='gray.200'
        borderRadius='md'
        boxShadow='sm'>
        <CardHeader>
          <Heading size='md' fontFamily='Cherry Swash' color='blue.500'>
            Wildcat Sponsor - $1,500 +
          </Heading>
        </CardHeader>
        <CardBody>
          <UnorderedList>
            <ListItem>Company Name/Logo on website/social media</ListItem>
            <ListItem>Logo Printed in Event Pamphlet</ListItem>
            <ListItem>Medium Company Logo on Event Banner/slideshow</ListItem>
            <ListItem>4 Tickets to VIP Cocktail Hour prior to event</ListItem>
          </UnorderedList>
        </CardBody>
      </Card>
      <Card
        border='1px'
        borderColor='gray.200'
        borderRadius='md'
        boxShadow='sm'>
        <CardHeader>
          <Heading size='md' fontFamily='Cherry Swash' color='blue.500'>
            Shine Like a Diamond Sponsor - $2,500 +
          </Heading>
        </CardHeader>
        <CardBody>
          <UnorderedList>
            <ListItem>Company Name/Logo on website/social media</ListItem>
            <ListItem>Logo Printed in Event Pamphlet</ListItem>
            <ListItem>Large Company Logo on Event Banner/slideshow</ListItem>
            <ListItem>Shoutout by Emcee at event</ListItem>
            <ListItem>6 Tickets to VIP Cocktail Hour prior to event</ListItem>
          </UnorderedList>
        </CardBody>
      </Card>
    </Stack>
  </Box>
)

export default EventBlueAndWhite2025
