import { Image, Link } from '@chakra-ui/react'
import React from 'react'

const EventCityBbq: React.FC = () => (
  <Link isExternal href='https://grouprai.se/s289725'>
    <Image
      src='/cityBbqEventDetail.jpeg'
      alt='City BBQ Shine & Dine - Join us at
      City Barbeque (8026 Burlington Pike) on Saturday, November 9th between
      11:00 AM - 8:00 PM and make a difference!'
    />
  </Link>
)
export default EventCityBbq
